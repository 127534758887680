@import '~bulma/bulma.sass';

html {
  overflow-y: auto;
  background-color: white;
}

/* do not group these rules */
*::-webkit-input-placeholder {
  color: darkgrey !important;
}

*:-moz-placeholder {
  /* FF 4-18 */
  color: darkgrey !important;
  opacity: 1;
}

*::-moz-placeholder {
  /* FF 19+ */
  color: darkgrey !important;
  opacity: 1;
}

*:-ms-input-placeholder {
  /* IE 10+ */
  color: darkgrey !important;
}

*::-ms-input-placeholder {
  /* Microsoft Edge */
  color: darkgrey !important;
}

*::placeholder {
  /* modern browser */
  color: darkgrey !important;
}

body {
  font-family: 'Open Sans', serif;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ReactTable .rt-thead .rt-tr {
  text-align: left;
}

.hero {
  font-family: 'Open Sans', serif;
  font-size: 14px;
  font-weight: 300;
}

.hero.is-success {
  background: #f2f6fa;
}

.hero .nav,
.hero.is-success .nav {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.hero .box {
  margin-top: 5rem;
}

.avatar {
  margin-top: -70px;
  padding-bottom: 20px;
}

.avatar img {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.hero input {
  font-weight: 300;
}

.hero p {
  font-weight: 700;
}

.hero p.subtitle {
  padding-top: 1rem;
}

.navbar-item img.pwf-logo {
  max-height: 2.5rem;
  margin-right: 5px;
}

nav.navbar {
  border-top: 4px solid #276cda;
  margin-bottom: 1rem;
}

.navbar-item.brand-text {
  font-weight: 300;
}

.navbar-item,
.navbar-link {
  font-size: 14px;
}

.desktop .navbar-item,
.navbar-link {
  font-weight: 700;
}

.sightingslist .box {
  margin-top: 0rem;
}

nav.pwf.breadcrumb {
  padding: 0.5rem 0.75rem;
}

label.checkbox > input {
  margin-right: 0.5rem;
}

.thumbnails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
}
.image-holder {
  position: relative;
  max-width: 120px;
  min-width: 40px;
  width: 20%;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 1px;
  padding-right: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.leaflet-container {
  z-index: 5;
}

.oriented-image {
  z-index: 6;
}

img.spinner {
  z-index: 6;
  max-width: 100px;
  max-height: 100px;
}

.aspect-box {
  position: relative;
  width: 100%; /* desired width */
  background-color: #fafafa;
}
.aspect-box:before {
  content: '';
  display: block;
  padding-top: 100%; /* initial ratio of 1:1*/
}

.aspect-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.button-image-overlay {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.button-image-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.modal-background {
  height: 100vh;
  width: 100vw;
  position: fixed;
}

.modal-card {
  position: fixed;
  top: 100px;
  max-width: 90vw;
  margin-top: auto;
  margin-bottom: auto;
}

.dropzone {
  background-color: #eee;
  color: #777;
}
.dropzone.disabled {
  opacity: 0.5;
}

.Content {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  box-sizing: border-box;
  width: 100%;
}

.Files {
  margin-left: 32px;
  align-items: flex-start;
  justify-items: flex-start;
  flex: 1;
  overflow-y: auto;
}

.Actions {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 32px;
}

.Filename {
  margin-bottom: 8px;
  font-size: 16px;
  color: #555;
}

.Row {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  padding: 8px;
  overflow: hidden;
  box-sizing: border-box;
}

.CheckIcon {
  opacity: 0.5;
  margin-left: 32px;
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.ProgressWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.ProgressBar {
  width: 100%;
  height: 8px;
  background-color: rgba(38, 143, 255, 0.2);
  border-radius: 5px;
}

.Progress {
  background-color: #007bff;
  height: 100%;
  margin: 0;
  border-radius: 5px;
}

div.fade.modal.show {
  width: 100vw;
  height: 100vh;
}

.Error {
  color: red;
}

body ol {
  margin: 1em 0;
  padding: 0 0 0 40px;
}

body ol li {
  list-style-type: decimal;
}

body ul {
  margin: 1em 0;
  padding: 0 0 0 40px;
}

body ul li {
  list-style-type: disc;
}

a.export-sightings {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 16rem;
  z-index: 5;
}

button.add-sightings {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 16rem;
  z-index: 5;
}

button.add-sightings-mobile {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 5;
}

nav.breadcrumb ul {
  margin: 2px;
}

nav.breadcrumb {
  margin-bottom: .25rem !important;
}

div.field.is-grouped.sign-up {
  justify-content: flex-end;
}