/* layers control */

.pwfsidebar-control-layers {
    /*box-shadow: 0 1px 5px rgba(0,0,0,0.4);*/
    background: #fff;
    border-radius: 5px;
    background-clip: padding-box;
}

.pwfsidebar-control-layers-toggle {
    /*background-image: url(images/layers.png);*/
    /*width: 36px;
    height: 36px;*/
}

.pwfsidebar-retina .pwfsidebar-control-layers-toggle {
    /*background-image: url(images/layers-2x.png);*/
    background-size: 26px 26px;
}

.pwfsidebar-touch .pwfsidebar-control-layers-toggle {
    /*width: 44px;
    height: 44px;*/
}

form#controllayers.pwfsidebar-control-layers-list {
    margin-bottom: 0px;
}

label.pwfsidebar>div>span {
    vertical-align: top;
}

.pwfsidebar-control-layers .pwfsidebar-control-layers-list {
    display: none;
}

.pwfsidebar-control-layers-expanded .pwfsidebar-control-layers-list {
    display: block;
    position: relative;
}

.pwfsidebar-control-layers-expanded {
    padding: 10px 0px 6px 6px;
    color: #333;
    background: #fff;
}

.pwfsidebar-control-layers-padded {
    padding: 10px 10px 6px 6px;
}

.pwfsidebar-control-layers-scrollbar {
    overflow-y: scroll;
    padding-left: 5px;
    padding-right: 10px;
    padding-top: 3px;
}

.pwfsidebar-control-layers-selector {
    margin-top: 2px;
    position: relative;
    top: 1px;
}

.pwfsidebar-control-layers label {
    display: block;
}

.pwfsidebar-control-layers-separator {
    height: 0;
    border-top: 1px solid #ddd;
    margin: 5px -10px 5px -6px;
    clear: both;
}

.pwfsidebar-control-layers-base {
    clear: both;
}

.pwfsidebar-control-layers-overlays {
    clear: both;
}

.pwfsidebar-control-layers-options {
    clear: both;
}

.pwfsidebar-control-layers-species {
    clear: both;
}

.pwfsidebar-control-layers-vessels {
    clear: both;
}

.pwfsidebar-control-layers>a {
    text-decoration: none;
    color: black;
}

.pwfsidebar-control-layers>a:link {
    text-decoration: none;
    color: black;
}

.pwfsidebar-control-layers>a:visited {
    text-decoration: none;
    color: black;
}

.leaflet-container .fa {
    display: inline-block;
    font-size: 12pt;
    background-color: white;
    color: black;
    width: 26px;
    height: 26px;
    border-radius: 5px;
    line-height: 26px;
    text-align: center;
    vertical-align: bottom;
}

.leaflet-container .fa-touch {
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.pwfsidebar-control-layers-label {
    text-align: left;
    vertical-align: top;
    font-weight: bold;
    float: left;
    font-size: 12px;
}

.pwfsidebar-control-layers-label.main {
    font-size: 14px;
    transform: translate(-2px, -4px);
}

.pwfsidebar-control-layers-close.fa.fa-times {
    vertical-align: top;
    float: right;
    text-align: right;
    transform: translate(0px, -6px);
}

input.form-control.input-sm.hasDatepicker {
    width: 90px;
    text-align: center;
    position: relative;
    z-index: 100000;
}

.pwfsidebar-control-layers-expanded .pwfsidebar-control-layers-toggle {
    display: none;
}