.sightingImageDisplay {
    text-align: center;
}

#startdatepicker {
    width: 25vw;
    max-width: 12em;
    text-align: center;
}

#enddatepicker {
    width: 25vw;
    max-width: 12em;
    text-align: center;
}

.pwfmodal {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9900;
}

.pwfmodal .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9950;
    background: rgba(0, 0, 0, 0.85);
}

.pwfmodal .modal_content {
    z-index: 9990;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90%;
    overflow: auto;
    background: #fff;
    box-sizing: border-box;
    padding: 20px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    width: 60%;
    /* This just a default width */
}

.pwfmodal .modal_content>h2 {
    font-size: 28px;
    font-weight: 200;
    margin: 20px 0 40px;
    text-align: center;
}

.pwfmodal .modal_content .buttons_wrapper {
    padding: 20px;
}

.pwfmodal .close_modal {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    font-size: 18px;
    opacity: 0.5;
    background: none;
    border: none;
    transition: opacity 0.2s ease;
}

.pwfmodal .close_modal:hover {
    opacity: 0.9;
}